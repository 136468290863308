@tailwind base;
@tailwind components;
@tailwind utilities;
/* styles.css or your main CSS/SCSS file */
@import url('https://fonts.googleapis.com/css2?family=Merriweather&family=Nunito+Sans:opsz,wght@6..12,400&display=swap');
@import url("https://fonts.googleapis.com/css2?family=Merriweather&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Merriweather&display=swap");

* {
    scroll-behavior: smooth;
}

input::placeholder {
    color: rgb(43, 42, 42);
    /* Change this to your desired color */
}

.dashboardscroll::-webkit-scrollbar {
    /* display: none; */
    width: 10px;
}



/* Track */
.dashboardscroll::-webkit-scrollbar-track {
    background: #f1f1f1;
}

/* Handle */
.dashboardscroll::-webkit-scrollbar-thumb {
    background: #6e6e6e;
}

/* Handle on hover */
.dashboardscroll::-webkit-scrollbar-thumb:hover {
    background: #555;
}

/* whatsapp .icon  start */
.whats-app {
    position: fixed;
    width: 60px;
    height: 60px;
    bottom: 150px;
    right: 15px;
    background-color: #25d366;
    color: #fff;
    border-radius: 50px;
    text-align: center;
    font-size: 30px;
    box-shadow: 2px 2px 3px #999;
    z-index: 100;
}

.my-float {
    margin-top: 16px;
}

/* whatsapp end  */

.swiper {
    width: 600px;
    height: 300px;
}

/* .swiper {
    width: 100%;
    height: 100%;
  } */

.swiper-slide {
    text-align: center;
    font-size: 18px;

    /* Center slide text vertically */
    display: flex;
    justify-content: center;
    align-items: center;
}

/* .swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
} */